// http://railsguides.net/cascading-selects-with-ajax-in-rails/

export class DynamicSelectable {
  static setup() {
    $('select[data-dynamic-selectable-url][data-dynamic-selectable-target]').each(function() {
      new DynamicSelectable($(this));
    })
  }

  constructor($select) {
    this.init($select);
  }

  init($select) {
    this.urlTemplate = $select.data('dynamicSelectableUrl');
    this.$targetSelect = $($select.data('dynamicSelectableTarget'));
    return $select.on('change', () => {
      this.clearTarget();
      const url = this.constructUrl($select.val());
      if (url) {
        return $.getJSON(url, data => {
          $.each(data, (index, record) => {
            const text = this.determineText(url, record);
            return this.$targetSelect.append(`<option value='${record.id}'>${text}</option>`);
          });
          return this.reinitializeTarget();
        });
      } else {
        return this.reinitializeTarget();
      }
    });
  }

  determineText(url, record) {
    const isProductQuantity = url.match('quantities.json');
    if (isProductQuantity) {
      return record.quantity + ' - ' + record.directions;
    } else {
      return record.name;
    }
  }

  reinitializeTarget() {
    return this.$targetSelect.trigger("change");
  }

  clearTarget() {
    return this.$targetSelect.html('<option></option>');
  }

  constructUrl(id) {
    if (id && (id !== '')) {
      return this.urlTemplate.replace(/:.+_id/, id);
    }
  }
}
