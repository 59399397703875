import debounce from 'lodash/debounce';
import { Helpers } from '../src/helpers';
import { Orders } from '../src/orders';

export class BarcodeScanner {
  static setup() {
    if ($('#barcode-scanner').length > 0) {
      this.setupBarcodeScanner();
      this.setupQuickNoteModal();
      return true;
    }
  }

  static setupBarcodeScanner() {
    const $barcodeForm = $('form#barcode-form');
    const $barcodeInput = $('input#scanner_barcode');

    $('#scan-modes-nav button[data-toggle="tab"]').on('shown.bs.tab', e => {
      const $tab = $(e.currentTarget);
      if ($barcodeInput.is(':hidden') || $tab.attr('id') == 'default-tab') {
        $barcodeInput.closest('.scanner_barcode').toggle(400);
      }

      if ($tab.attr('id') == 'move-orders-tab') {
        const $form = $(`${$tab.data('target')} form`);
        $form.find('#order_area_id').val(localStorage.getItem('barcode_scan_area_id'));
        $form.find('#order_activity_id').val(localStorage.getItem('barcode_scan_activity_id'));

        if ($form.find('#order_area_id').val().length > 0) {
          $barcodeInput.focus();
        } else {
          $form.find('#order_area_id').focus();
        }
      } else {
        $barcodeInput.focus();
      }
    });

    const $moveOrderForm = $('#move-orders-mode form.order-barcode-form');
    $moveOrderForm.find('#order_area_id').val(localStorage.getItem('barcode_scan_area_id'));
    $moveOrderForm.find('#order_activity_id').val(localStorage.getItem('barcode_scan_activity_id'));
    $('.order-barcode-form #order_pop_up_mode').prop('checked', localStorage.getItem('barcode_scan_pop_up_mode') == 'true');
    Orders.setupActivitySelect($moveOrderForm);

    $moveOrderForm.find('#order_area_id').on('change', () => {
      $moveOrderForm.find('#order_activity_id').focus();
    });

    $moveOrderForm.find('#order_activity_id').on('change', function() {
      $barcodeInput.focus();
      localStorage.setItem('barcode_scan_area_id', $moveOrderForm.find('#order_area_id').val());
      localStorage.setItem('barcode_scan_activity_id', $(this).val());
    });

    $('.order-barcode-form #order_pop_up_mode').on('change', function() {
      $barcodeInput.focus();
      localStorage.setItem('barcode_scan_pop_up_mode', $(this).is(':checked'));
    });

    $barcodeForm.on('submit', e => {
      e.preventDefault();
    });

    // Barcode Scanner input reader
    $barcodeInput.on('keyup', debounce(e => {
      console.log({ event: 'barcode keyup', key: e.originalEvent.key, keycode: e.originalEvent.keyCode });
      if (e.originalEvent.keyCode == 13) { // Scanners should be setup to send enter after a successful scan
        this.respondToBarCodeInput();
      }
    }, 200));

    $barcodeForm.find('button.submit').on('click', () => {
      this.respondToBarCodeInput();
    });
  }

  static respondToBarCodeInput() {
    const $barcodeInput = $('input#scanner_barcode');
    const $scanModeForm = $('#scan-modes .tab-pane.active form.order-barcode-form');
    const $orderId = $scanModeForm.find('#order_id');
    const $basketNumber = $scanModeForm.find('#order_basket_number');
    const scanMode = $scanModeForm.closest('.tab-pane.active').attr('id');
    $barcodeInput.val($barcodeInput.val().replace(/[^0-9]/g, ''));
    if ($barcodeInput.val() == '') {
      return $barcodeInput.val('').focus();
    } else if ($barcodeInput.val().startsWith('0')) {
      $basketNumber.val($barcodeInput.val().replace('0', ''));
    } else {
      $orderId.val($barcodeInput.val());
    }
    $barcodeInput.val('').focus();

    switch(scanMode) {
      case 'move-orders-mode': {
        let orderData = { order: { barcode_scan: true, id: $orderId.val(), activity_id: $scanModeForm.find('#order_activity_id').val() } };
        if ($basketNumber.val().length > 0) {
          orderData.order.basket_number = $basketNumber.val();
        }
        $.ajax({
          type: 'PATCH',
          url: `/orders/${$orderId.val() || $basketNumber.val()}.js`,
          data: orderData,
          dataType: 'script',
          error(data) {
            Helpers.renderHtmlResponse(data);
          }
        });
        break;
      }
      case 'edit-orders-mode': {
        if ($scanModeForm.find('#order_pop_up_mode').is(':checked')) {
          const url = $scanModeForm.data('edit-url') + '?' + $.param({ order: { barcode_scan: true, id: $orderId.val(), basket_number: $basketNumber.val() } });
          window.open(url, '_blank').focus();
        } else {
          const url = $scanModeForm.data('edit-url');
          $.ajax({
            type: 'GET',
            url: `${url}.js`,
            data: {
              order: {
                barcode_scan: true,
                id: $orderId.val(),
                basket_number: $basketNumber.val()
              }
            },
            dataType: 'script',
            error(data) {
              Helpers.renderHtmlResponse(data);
            }
          });
        }
        break;
      }
      case 'assign-baskets-mode': {
        if ($orderId.val().length == 0 || $basketNumber.val().length == 0) {
          return null;
        }

        $.ajax({
          type: 'PATCH',
          url: `/orders/${$orderId.val()}.js`,
          data: {
            order: {
              barcode_scan: true,
              id: $orderId.val(),
              basket_number: $basketNumber.val()
            }
          },
          dataType: 'script',
          error(data) {
            Helpers.renderHtmlResponse(data);
          }
        });
        break;
      }
    }
  }

  static setupQuickNoteModal() {
    $('button.add-note').click(function() {
      const $modal = $('#quick-note-modal');
      const $btn = $(this);

      $modal.find('.order-title').empty();
      $btn.closest('tr').find('.order-link').clone().prepend('Order# ').appendTo('#quick-note-modal .order-title');
      $btn.closest('tr').find('.patient-link').clone().addClass('ml-3').appendTo('#quick-note-modal .order-title');
      $modal.find('input#order_id').val($btn.data('order-id'));
      $modal.find('.alert').remove();
      $modal.modal('show');
    });

    $('#quick-note-form form').on('submit', function(e) {
      e.preventDefault();

      const $modal = $('#quick-note-modal');
      const $form = $(this);
      const order_id = $form.find('#order_id').val();
      let note = $('#order_note').val();

      $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: { order: { id: order_id, note: note } },
        success() {
          $modal.modal('hide');
        },
        error(data) {
          $form.find('.alert').remove();
          $form.prepend(`
            <div class="alert in alert-danger">
              <button type="button" class="close" data-dismiss="alert">×</button>
              ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to add note, please try again.'}
            </div>
          `);
        }
      });
    });
  }
}
