export class PatientBatchCommunications {
  static setup() {
    if ($("#message_options_message").length) {
      PatientBatchCommunications.checkBatchMessageDisplay();
    }
    $("#message_options_message").change(() => PatientBatchCommunications.checkBatchMessageDisplay());
    PatientBatchCommunications.checkSelectedFilterMode();
    $('#patient_batch_communication_filter_mode_0').on('change', () => PatientBatchCommunications.checkSelectedFilterMode());
    return $('#patient_batch_communication_filter_mode_1').on('change', () => PatientBatchCommunications.checkSelectedFilterMode());
  }

  static checkSelectedFilterMode() {
    if ($('#patient_batch_communication_filter_mode_0').is(':checked') === true) {
      $('.patient_batch_communication_patient_ids').hide();
      return $('.patient-batch-order-filter').show();
    } else {
      $('.patient_batch_communication_patient_ids').show();
      return $('.patient-batch-order-filter').hide();
    }
  }

  static checkBatchMessageDisplay() {
    if ($("#message_options_message").val() === "Other") {
      $("#patient_batch_communication_message").val('');
      return $(".patient_batch_communication_message").removeClass("d-none");
    } else {
      const message_value = $("#message_options_message").val();
      $(".patient_batch_communication_message").addClass("d-none");
      return $("#patient_batch_communication_message").val(message_value);
    }
  }
}

