var QRCode = require('qrcode-svg');

export class TwoFactorAuthenticationSetupModal {
    static setup() {
        let $modal = $('#setup-2fa-modal');
        // When 2FA setup modal is loaded
        // setup a twilio verify factor for the user
        // and generate a qr code / secret code
        $modal.on('show.bs.modal', function () {
            $.ajax({
                type: 'GET',
                url: $modal.data('setupUrl'),
                success(data) {
                    if (data && data.qr_code) {
                        let qrcode = new QRCode({
                            content: data.qr_code,
                            join: true,
                            container: 'svg-viewbox'
                        });

                        $modal.find('#qr-code').html(qrcode.svg());
                        $modal.find('#secret-code').html(data.secret_code);
                    }
                }
            });
        });

        // Intercept form submission and send register TOTP via ajax
        // if it passes, hide the modal, show a confimration alert
        // else show an invalid token error alert
        let $form = $('form.totp_setup');
        $form.on('submit', function(event) {
            event.preventDefault();

            $.ajax({
                type: 'PUT',
                url: $modal.data('registerUrl'),
                data: { token: $form.find('#totp_setup_token').val() },
                success(data) {
                    $('#flash-messages').html(`
                        <div class="alert in alert-success">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            ${data.message}
                        </div>
                    `);
                    $modal.modal('hide');
                    $('.setup-2fa-btn').remove();
                },
                error(data) {
                    console.error(data);
                    $('#flash-messages').html(`
                        <div class="alert in alert-danger">
                            <button type="button" class="close" data-dismiss="alert">×</button>
                            ${data && data.message ? data.message : 'Failed to setup 2FA, please try again.'}
                        </div>
                    `);
                }
            });
        });
    }
}
