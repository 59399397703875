export class Prescriptions {
    static setup() {
        Prescriptions.setupRefillCycleEvents();
        Prescriptions.requestConsentOnSubmit();
    }

    static setupRefillCycleEvents() {
        let cycleSelect = $("select#prescription_auto_refill_cycle_select");
        let cycleInput = $('input#prescription_auto_refill_cycle');
        Prescriptions.showOrHideCycleInput(cycleInput.val());

        cycleSelect.change(function() {
            Prescriptions.showOrHideCycleInput($(this).val());
        })
    }

    static showOrHideCycleInput(selectedCycleValue) {
        let cycleSelect = $("select#prescription_auto_refill_cycle_select");
        let cycleOptions = cycleSelect.find('option').map(function() {return $(this).val();}).get();
        let cycleInput = $('input#prescription_auto_refill_cycle');

        if(selectedCycleValue === '') {
            cycleInput.closest('.form-group').show();
        } else if(cycleOptions.includes(selectedCycleValue)) {
            cycleInput.closest('.form-group').hide();
        }


        cycleInput.val(selectedCycleValue);
    }

    static requestConsentOnSubmit() {
        const $form = $('form.edit_prescription');

        $form.on('submit', function(e) {
            if ($('#prescription_consent_provided').val() != '') {
                return null;
            }

            e.preventDefault();
            $.ajax({
                url: $form.data('consent-url'),
                type: 'GET',
                data: $form.serialize(),
                success(data) {
                    if (data.consent_provided != undefined) {
                        $('#prescription_consent_provided').val(data.consent_provided == true);
                        $form.submit();
                    }
                }
            });
        });
    }
}
