require('trix');
require('@rails/actiontext');

import 'trix/dist/trix.css';
import '../src/rad_common_js/css/actiontext.scss';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'jquery-ui/themes/base/all.css';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
import '../src/rad_common_js/css/rad_common/jquery-ui-overrides.scss';
import '../src/rad_common_js/css/bootstrap_and_overrides.scss';
import '../src/rad_common_js/css/main.css.scss';
import '@fortawesome/fontawesome-free/css/all';

import { RadCommon } from '../src/rad_common_js/radCommon';
RadCommon.setup();

import './app_specific.js'
