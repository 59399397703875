import { ConsentModal } from '../src/consent_modal';
import { AddressVerificationModal } from '../src/address_verification_modal';
import { Orders } from '../src/orders';
import { RadCommonGeneral } from '../src/rad_common_js/radCommonGeneral';

export class Helpers {
  static setup() {
    $('.checkbox-fa').each(function () {
      const $el = $(this);
      const $input = $el.find('input[type="checkbox"]');

      $el.prepend(`<i class="custom-checkbox ${$input.prop('checked') ? 'checked' : 'unchecked'}"></i>`);

      $el.find('.custom-checkbox').on('click', function() {
        $el.find('.custom-checkbox').toggleClass(['checked', 'unchecked']);
        $input.prop('checked', !$input.prop('checked')).trigger('change');
      });
    });

    window.copyToClipboard = function(text) {
      navigator.clipboard.writeText(text);
    };

    window.setupConsentModal = function() {
      ConsentModal.setup();
    };

    window.setupAddressVerificationModal = function() {
      AddressVerificationModal.setup();
    };

    window.setupOrdersForm = function(form = null) {
      Orders.setupForm(form);
    };

    $('.btn.btn-collapse').each(function() {
      const $btn = $(this);
      if ($($btn.data('target')).length == 0) {
        $btn.hide();
      }
    });

    // Note: Patch for readmore when not initially visible
    // https://github.com/jedfoster/Readmore.js/issues/128
    $('.collapseable.collapse').on('shown.bs.collapse', function() {
      const $btn = $('button.readmore-toggle.collapsed');
      if ($btn.length > 0) {
        $btn.toggleClass('collapsed');
        $('.read-more').readmore('toggle');
        $btn.find('i.fa').toggleClass('fa-square-plus fa-square-minus');
        $btn.find('span').text($btn.hasClass('collapsed') ? 'Collapse All' : 'Expand All');
      }
      RadCommonGeneral.readmore();
    });

    $('.alert.warnings').each(function() {
      const $alert = $(this);
      if ($alert.find('ul li').length > 0) {
        $alert.toggle(300);
      }
    });
  }

  static renderHtmlResponse(data) {
    // jquery team broke this in 3.5, they recommended this workaround
    // https://github.com/jquery/jquery/issues/4655#issuecomment-640116500
    if (data && data.status == 422 && data.responseText) {
      $.globalEval(data.responseText);
    }
  }
}
