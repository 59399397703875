import {Signature} from "./signature";

export class ProductIngredients {
  static setup() {

    let padSetup = false;
    $('.product-ingredient-handwritten-title').hide();
    ProductIngredients.checkPadSetup();
    return $('#product_ingredient_ingredient_id').on('change', function(){
      if(ProductIngredients.checkPadSetup(padSetup)) {
        padSetup = true;
      }
    });
  }

  static checkPadSetup(padSetup) {
    if ($('#product_ingredient_ingredient_id :selected').attr('controlled_substance') === 'true') {
      $('.product-ingredient-handwritten-title').show();
      if (!padSetup) {
        Signature.setupPad();
        return true;
      }
    } else {
      $('.product-ingredient-handwritten-title').hide();
    }

    return false;
  }
}
$(function() {

});
