export class UserNotifications {
  static setup() {
    $('.modal_link').prop('disabled', false);
    $('.modal_link').click(function(e) {
      UserNotifications.deleteUserNotification(event);
    });
  }

  static deleteUserNotification(event, postFunction) {
    const element = $(event.currentTarget);
    const notification = element.data('notification');

    if (element.prop('tagName') === 'A') {
      event.preventDefault();
      const link_location = element.attr('href');
      UserNotifications.user_notification_sent(
        notification,
        data => window.location = link_location);
      return false;
    } else if ((element.prop('tagName') === 'BUTTON') && (element.attr('type') !== 'submit')) {
      UserNotifications.user_notification_sent(
        notification,
        data => {
          if (element.data('remote')) {
            element.closest('.modal').modal('hide');
          } else {
            window.location.reload();
          }
          postFunction();
        });
      return false;
    } else if (( (element.prop('tagName') === 'BUTTON') && (element.attr('type') === 'submit') ) || ( (element.prop('tagName') === 'INPUT') && (element.attr('type') === 'submit') )) {
      event.preventDefault();
      const form = element.closest('form');
      UserNotifications.user_notification_sent(
        notification,
        data => form.submit());
      return false;
    }
  }

  static user_notification_sent(user_notification_id, postFunc) {
    $.ajax({
      url: '/user_notifications/' + user_notification_id,
      type: 'POST',
      dataType: 'json',
      data: { '_method': 'delete'},
      success: postFunc
    })
  }
}
