import { Calendar } from 'fullcalendar/dist/fullcalendar.min';

export class PrescriberEvents {
    static setup() {
        let calendarEl = $('#calendar');
        if (calendarEl.length) {
            const calendar_height = window.innerHeight - calendarEl.position().top - 120;
            const spinner = $('.spin-box');
            let calendar = new Calendar(calendarEl, {
                events: '/prescriber_events/calendar.json' + window.location.search,
                height: calendar_height,
                scrollTime: "08:00:00",
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'month,agendaWeek,agendaDay'
                },
                loading(isLoading, view){
                    return spinner.toggleClass('d-none', !isLoading);
                },
                defaultView: 'agendaWeek',
                eventRender(event, element) {
                    element.find(".fc-title").prepend("<i class='fa fa-" + event.icon + "'/> ");
                    return element;
                }
            });
            calendar.render();
        }

        if ($('#prescriber_event_event_type_id').length) {
            PrescriberEvents.checkEventTypeHasSchedule($("#prescriber_event_event_type_id"), false );
            return $("#prescriber_event_event_type_id").change(function() {
                return PrescriberEvents.checkEventTypeHasSchedule( $(this), true );
            });
        }
    }

    static checkEventTypeHasSchedule(event_type_field, default_date ) {
        if (event_type_field.val()) {
            let hours = 1;
            if ($(event_type_field).find("option:selected").data("has-schedule")) {
                return $("input.datetime_local").each(function() {
                    $(this).closest(".form-group").show();
                    if (default_date) {
                        return dateTimePicker.set_date( $(this), moment( PrescriberEvents.getNextHour(hours++) ) );
                    }
                });
            } else {
                return $("input.datetime_local").each(function() {
                    $(this).closest(".form-group").hide();
                    return $(this).attr( 'value', '' );
                });
            }
        } else {
            return $("input.datetime_local").each(function() {
                return $(this).closest(".form-group").hide();
            });
        }
    }

    static getNextHour(hours){
        const now = moment();
        return moment().add( 'minutes', 60 - now.minutes() ).add( 'hours', hours - 1 ).seconds(0);
    }
}
