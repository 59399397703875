export class ForYourRecordsFax {
  static setup() {
    $('#new_for_your_records_fax input[type=submit].modal_link').click(function(e) {
      let fax_number = undefined;
      let stamp = undefined;
      fax_number = $('#for_your_records_fax_fax_number');
      stamp = $('#for_your_records_fax_stamp');
      if ((fax_number.val() === 'undefined') || (fax_number.val() === '')) {
        alert('You must enter a Fax Number');
        e.stopImmediatePropagation();
        return e.preventDefault();
      } else if ((stamp.val() === 'undefined') || (stamp.val() === '')) {
        alert('You must choose a stamp for this fax');
        e.stopImmediatePropagation();
        return e.preventDefault();
      }
    });
  }
}
