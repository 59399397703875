export class GeoLocator {
  static setup() {
    if ($('#search_location').length && !Cookies.get("lat_lng")) {
      return $('#search_location').change(function(event) {
        event.preventDefault();
        const button = $(this);
        return GeoLocator.getGeoLocation();
      });
    }
  }

  static getGeoLocation() {
    GeoLocator.showLoadingDialog();
    return navigator.geolocation.getCurrentPosition(position => GeoLocator.setGeoCookie(position));
  }

  static setGeoCookie(position) {
    Cookies.set( "user_latitude", escape( position.coords.latitude ) );
    Cookies.set( "user_longitude",escape( position.coords.longitude ) );
    return GeoLocator.hideLoadingDialog();
  };

  static showLoadingDialog() {
    $(".modal#retrieving-location").modal('show');
  }

  static hideLoadingDialog() {
    $(".modal#retrieving-location").modal('hide');
  }
}
