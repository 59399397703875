import {StoredPaymentMethod} from './stored_payment_methods';
import {Patients} from './patients';

export class Payments {
  constructor() {
    this.$form = $("form#payment_form");
    this.guestPay = $('#guest-pay').length == 1;
    this.$confirmationModal = $('#confirmation-modal');
  }

  static setup() {
    let payments = new Payments();
    payments.setup();
  }

  setup() {
    $(document).ready(function () {
        $('input[type="number"].currency').each(function() {
            $(this).val(parseFloat($(this).val()).toFixed(2));
        });

        $('input[type="number"].currency').change(function() {
            $(this).val(parseFloat($(this).val()).toFixed(2));
        });
    });

    let payments = this;

    if (payments.$form.length == 0) {
      return null;
    }

    payments.$form.find("input[name='payment_form[payment_method]']").change(function() {
      return payments.togglePaymentType();
    });

    const $consultationRequested = payments.$form.find('input[name="payment_form[consultation_request]"].radio_buttons:checked');
    payments.$form.find('.payment_form_consultation_request_note').toggle($consultationRequested.val() == 'true');
    payments.$form.find('input[name="payment_form[consultation_request]"].radio_buttons').on('click', function() {
      payments.$form.find('.payment_form_consultation_request_note').toggle(this.value == 'true');
    });

    payments.setupGuestPay();

    payments.$form.find('input#payment_form_payment_card_number').on('keypress', function(event) {
      if (event.which == 13) {
        var readerData = $(event.target).val();
        $form.find('#error-card-swipe').addClass('d-none');
        if (readerData.startsWith('%')) {
          var card = readerData.match(/%B([0-9]+)\^([A-Z /.]+)\/([A-Z /.]*)\^([0-9]{2})([0-9]{2})/);
          if (card) {
            var number = card[1];
            var lastName = card[2].trim();
            var firstName = card[3].trim().split('.')[0];
            var expMonth = card[5];
            var expYear = '20' + card[4];
            var fullName = firstName + " " + lastName;

            $form.find('input#payment_form_payment_card_cardholder_name').val(fullName);
            $form.find('select#payment_form_payment_card_expiration_month').val(expMonth);
            $form.find('select#payment_form_payment_card_expiration_year').val(expYear);
            $form.find('input#payment_form_payment_card_number').val(number);
          } else {
            $form.find('#error-card-swipe').text('Unable to parse card swipe data.  Please enter information manually.  If a card was not swiped, please ignore this message.').removeClass('d-none');
          }
        }
        return false;
      }
    });

    payments.$form.find('a#card-focus-link').on('click', function(event) {
      event.preventDefault();
      payments.$form.find('input#payment_form_payment_card_number').focus();
    });

    payments.$form.on('submit', function(e) {
      const patientPaymentForm = payments.$form.hasClass('patient-payment-form');
      const consentProvided = payments.$form.find('#payment_form_consent_provided').val() == 'true';
      const usingStoredPaymentMethod = payments.getPaymentTypeValue() == "stored_payment_method";
      const adjustment = payments.getPaymentTypeValue() == 'adjustment';
      const $storedPaymentMethod = payments.$form.find("select[name='payment_form[stored_payment_method_id]']:not([disabled])");
      const autopayEnabled = $storedPaymentMethod.val() != '' && $storedPaymentMethod.find('option:selected').data('autopay');
      const needsConsent = patientPaymentForm && !adjustment && (!usingStoredPaymentMethod || (usingStoredPaymentMethod && !autopayEnabled));

      if (needsConsent && !consentProvided) {
         e.preventDefault();
         payments.$form.find('input[type=submit]').attr('disabled', true);
         $.ajax({
           url: '/user_notifications.js',
           type: 'POST',
           data: {
             user_notification: {
               modal_name: 'consent',
               resource_type: 'Patient',
               resource_id: payments.$form.find('#payment_form_patient_id').val(),
               metadata: { consent_type: 'payment_authorization' },
               user_id: $('body.main-body').data('current-user-id')
             }
           }
         });
      } else {
        if (payments.guestPay && !payments.$confirmationModal.data('confirmed') && payments.$form.find('input[name="payment_form[fill_consent]"][value="smart_fill"]').length > 0 && payments.$form.find('input[name="payment_form[fill_consent]"][value="smart_fill"]:checked').length == 0) {
          e.preventDefault();
          payments.$form.find('input[type="submit"]').prop('disabled', true);
          payments.$confirmationModal.modal('show');
        } else {
          StoredPaymentMethod.submitPaymentMethod(this, e, payments.getPaymentTypeValue() == 'payment_card');
        }
      }
    });

    payments.$form.find("select#payment_form_division_id").change(function() {
      return payments.toggleStoredPaymentMethods();
    });

    if (payments.$form.get(0)) {
      payments.togglePaymentType();
      payments.toggleStoredPaymentMethods();
    }
  }

  setupGuestPay() {
    let payments = this;

    if (!payments.guestPay) {
      return false;
    }

    Patients.setupPatientContactInfoForm();

    if (payments.$form.find('#payment_form_fill_consent_one_time_fill').is(':checked')) {
      payments.toggleSmartFillOptions(payments.$form.find('#payment_form_fill_consent_one_time_fill'));
    }
    payments.$form.find('input[name="payment_form[fill_consent]"].radio_buttons').on('click', function() {
      payments.toggleSmartFillOptions($(this));
    });

    payments.$form.find('input[name="payment_form[amount]"].radio_buttons').on('click', function() {
      $('#payment_form_amount').val(this.value);
      if ($(this).data('cycle') == 30) {
        payments.$form.find('#payment_form_fill_consent_one_time_fill').click();
      }

      if (payments.$form.find('.amt-medication-bottle').length > 0) {
        if ($(this).data('volume') == 8) {
          payments.$form.find('.amt-medication-bottle.xs img').clone().appendTo('.amt-medication-bottle.xs');
          payments.$form.find('.amt-medication-bottle.lg img').clone().appendTo('.amt-medication-bottle.lg');
        } else if (payments.$form.find('.amt-medication-bottle.xs img').length == 2) {
          payments.$form.find('.amt-medication-bottle.xs img:last').remove();
          payments.$form.find('.amt-medication-bottle.lg img:last').remove();
        }
      }
    });

    if (payments.$form.find('#payment_form_fill_consent_smart_fill').is(':checked')) {
      payments.$form.find('#payment_form_store_payment_card').prop('checked', true).prop('disabled', true);
    }

    if (payments.$form.find('.amt-medication-bottle').length > 0) {
      const $amount = payments.$form.find('input[name="payment_form[amount]"]:checked');
      if ($amount.data('volume') == 8) {
        payments.$form.find('.amt-medication-bottle.xs img').clone().appendTo('.amt-medication-bottle.xs');
        payments.$form.find('.amt-medication-bottle.lg img').clone().appendTo('.amt-medication-bottle.lg');
      }
    }

    payments.$confirmationModal.find('.confirm-button').on('click', function(e) {
      payments.$confirmationModal.modal('hide');
      payments.$confirmationModal.data('confirmed', true);

      payments.$form.find('#payment_form_fill_consent_smart_fill').click();
      payments.$form.find('input[type=submit]').attr('disabled', false);
      payments.$form.find('.alert.alert-danger').remove();
      $('html, body').animate({ scrollTop: $('.main-body .navbar').offset().top }, 800);
    });

    payments.$confirmationModal.find('.cancel-button').on('click', function(e) {
      payments.$confirmationModal.modal('hide');
      payments.$confirmationModal.data('confirmed', true);
      payments.$form.submit();
    });
  }

  toggleSmartFillOptions(fillConsent) {
    let payments = this;
    const $amount = payments.$form.find('input[name="payment_form[amount]"]:checked');
    const smartFillSelected = fillConsent.val() == 'smart_fill';

    if (smartFillSelected) {
      payments.$form.find('#payment_form_store_payment_card').prop('checked', true).prop('disabled', true);

      if ($amount.data('cycle') == 30) {
        // smart fill only available on 90 day cycle
        payments.$form.find('input[name="payment_form[amount]"]').not(':checked').click();
      }
    } else {
      payments.$form.find('#payment_form_store_payment_card').prop('disabled', false);
    }

    if ($amount.data('discountedRate') > 0) {
      const balance = parseFloat(payments.$form.find('input[name="payment_form[current_balance]"]').val());

      payments.$form.find('input[name="payment_form[amount]"].radio_buttons').each(function() {
        const rate = parseFloat($(this).data(smartFillSelected ? 'discountedRate' : 'rate'));
        $(this).val((balance + rate).toFixed(2));

        if (smartFillSelected && !$(this).siblings('label').find('.full-rate').is(':visible')) {
          $(this).siblings('label').find('.full-rate').toggle(true);
        } else {
          $(this).siblings('label').find('.full-rate').toggle(false);
        }
        $(this).siblings('label').find('.total').text(`$${rate.toFixed(2)}`);
        $(this).siblings('label').find('.total-per-oz').text(`$${parseFloat(rate / $(this).data('volume')).toFixed(2)}`);
      });
    }

    $('#payment_form_amount').val(payments.$form.find('input[name="payment_form[amount]"]:checked').val());
  }

  toggleStoredPaymentMethods() {
    let selectedDivision = this.$form.find("select[name*='[division_id']").val();
    this.$form
        .find("select[data-division-id]")
        .attr("disabled", true)
        .closest("div")
        .hide();
    return this.$form
        .find("select[data-division-id='" + selectedDivision + "']")
        .removeAttr("disabled")
        .closest("div")
        .show();
  }

  getPaymentTypeValue = function() {
    return this.$form.find("input[name='payment_form[payment_method]']:checked").val();
  }

  togglePaymentType = function() {
    let payments = this;
    payments.$form.find("fieldset[id^='payment-method']").attr("disabled", true).hide();
    return this.$form
        .find(
            "fieldset#payment-method-" + payments.getPaymentTypeValue().replace(/_/g, "-")
        )
        .removeAttr("disabled")
        .show();
  }
}
