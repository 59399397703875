export class AddressVerificationModal {
  static setup() {
    if ($('#address-verification-modal').length > 0) {
      this.setupAddressVerificationModal();
    }
  }

  static setupAddressVerificationModal() {
    const $addressVerificationModal = $('#address-verification-modal');
    const $form = $('form.address-verification-required');
    const urlParams = new URLSearchParams(window.location.search);

    $form.on('submit', function (event) {
      $form.find('input[type="submit"]').prop('disabled', true);

      if ($form.data('address-verified') || $form.find('#patient_bypass_address_validation').val() == 'true') {
        return null;
      }

      if ($('#patient_registration_form').length > 0 && !$('#confirmation-modal').length > 0 && !$('#confirmation-modal').data('confirmed')) {
        return null;
      }

      event.preventDefault();
      $.ajax({
        type: 'GET',
        url: $addressVerificationModal.data('addressVerificationUrl'),
        data: {
          patient: {
            encoded_id: urlParams.get('patient_id'),
            address_1: $form.find('#patient_address_1').val(),
            address_2: $form.find('#patient_address_2').val(),
            city: $form.find('#patient_city').val(),
            state: $form.find('#patient_state').val(),
            zipcode: $form.find('#patient_zipcode').val()
          }
        },
        success(data) {
          if (data && data.address_1) {
            $form.find('#patient_bypass_address_validation').val(false);
            if (data.exact_match) {
              $form.data('address-verified', true);
              $form.submit();
            } else {
              $addressVerificationModal.find('.original-address').html(`
                ${$form.find('#patient_address_1').val()}</br>
                ${$form.find('#patient_address_2').val() != null && $form.find('#patient_address_2').val().length > 0 ? ($form.find('#patient_address_2').val() + '</br>') : ''}
                ${$form.find('#patient_city').val()}, ${$form.find('#patient_state').val()} ${$form.find('#patient_zipcode').val()}
              `);
              $addressVerificationModal.find('.verified-address .address-1').text(data.address_1);
              $addressVerificationModal.find('.verified-address .address-2').text(data.address_2.length > 0 ? ' ' + data.address_2 : '');
              $addressVerificationModal.find('.verified-address .city').text(data.city);
              $addressVerificationModal.find('.verified-address .state').text(data.state);
              $addressVerificationModal.find('.verified-address .zipcode').text(data.zipcode);
              $addressVerificationModal.modal('show');
              $form.find('input[type="submit"]').prop('disabled', false);
            }
          } else {
            $form.find('#patient_bypass_address_validation').val(true);
            $form.submit();
          }
        },
        error() {
          $form.find('#patient_bypass_address_validation').val(true);
          $form.submit();
        }
      });
    });

    $addressVerificationModal.find('.original-address-button').on('click', function() {
      $form.find('#patient_bypass_address_validation').val(true);
      $addressVerificationModal.modal('hide');
      $form.find('input[type="submit"]').prop('disabled', true);
      $form.submit();
    });

    $addressVerificationModal.find('.verified-address-button').on('click', function() {
      const $verifiedAddress = $addressVerificationModal.find('.verified-address');
      $form.find('#patient_address_1').val($verifiedAddress.find('.address-1').text());
      $form.find('#patient_address_2').val($verifiedAddress.find('.address-2').text().trim());
      $form.find('#patient_city').val($verifiedAddress.find('.city').text());
      $form.find('#patient_state').val($verifiedAddress.find('.state').text());
      $form.find('#patient_zipcode').val($verifiedAddress.find('.zipcode').text());
      $addressVerificationModal.modal('hide');
      $form.find('input[type="submit"]').prop('disabled', true);
      $form.data('address-verified', true);
      $form.submit();
    });

    $form.find('.address-fields input, .address-fields select').on('change', function() {
      $form.find('#patient_bypass_address_validation').val(false);
    });
  }
}
