import $ from 'jquery'

export class PrescriptionRequestProducts {
  static setup() {
    $('#prescription_request_product_product_quantity_id').change(function() {
      if ($(this).val()) {
        return $.getJSON(`/product_quantities/${$(this).val()}/product_description`, function(data) {
          $('#prescription_request_product_name').val(data.product_name);
          $('#prescription_request_product_quantity').val(data.quantity);
          $('#prescription_request_product_directions').val(data.directions);
          $('#prescription_request_product_directions_2').val(data.directions_2);
          $('#prescription_request_product_ingredient_description').val(data.description);
          return $('#prescription_request_product_quantity_description').val(data.quantity_description);
        });
      } else {
        $('#prescription_request_product_name').val('');
        $('#prescription_request_product_quantity').val('');
        $('#prescription_request_product_directions').val('');
        $('#prescription_request_product_directions_2').val('');
        $('#prescription_request_product_ingredient_description').val('');
        return $('#prescription_request_product_quantity_description').val('');
      }
    });
  }
}
