import SignaturePad from '../src/signature_pad'

export class Signature {
  static setup() {
    if($('.signature-pad-override').length == 0){
      Signature.setupPad()
    }
  }

  static resizeCanvas(canvas) {
    let ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    if($('.signature_pad.long').length > 0){
      canvas.height = canvas.offsetHeight * ratio;
    }else{
      canvas.height = 200;
    }
    canvas.getContext("2d").scale(ratio, ratio);
  }

  static setupPad(){
    let canvas = document.querySelector("canvas");
    if (canvas){
      canvas.height = canvas.offsetHeight;
      canvas.width = canvas.offsetWidth;
      window.onresize = Signature.resizeCanvas(canvas);
      Signature.resizeCanvas(canvas);
      let signature_pad = new SignaturePad(canvas, { dotSize: 3.0, minWidth: 2.0, maxWidth: 4.0 });
      $('.signature_pad_clear').click(function() { signature_pad.clear() });
      $('.signature_pad_save').click(function(event) {
        if(!signature_pad.isEmpty()){
          $('.signature_pad_input').val(signature_pad.toDataURL());
        }
      });
    }
  }
}
