require('jquery-ui/ui/effects/effect-shake')

export class PatientCommunications {
  constructor() {
    this.notification_interval = null;
    this.setup();
  }

  static setup() {
    let communications = new PatientCommunications();
    communications.setup();
  }

  setup() {
    const communications = this;
    const user_id = $('body').data('current-user-id');
    if (window.Pusher && Pusher.instance) {
      const me_channel = Pusher.instance.subscribe('me_count' + user_id);
      const other_channel = Pusher.instance.subscribe('other_count');

      me_channel.bind('update', function (data) {
        $('.me-count').html('Me  ' + data.me_count);
        communications.clearNotificationInterval();
        return communications.shakeNotification();
      });

      other_channel.bind('update', function (data) {
        $('.other-count').html('Others  ' + data.other_count);
        communications.clearNotificationInterval();
        return communications.shakeNotification();
      });
    }

    if ($('#pending-communications').length) {
      const spin_box = $('.spin-box');
      let addRefreshButton = true;
      return $('#communication-modal').on('show.bs.modal', function (e) {
        communications.clearNotificationInterval();
        spin_box.show();
        // The modal for patient communications creates a self-referencing iframe
        // This needs to be refactored to AJAX calls, as this is very counterintuitive
        if ($('#communication-modal iframe').length === 0) {
          const communication_frame = $('<iframe src=\'/patient_communications/console\'></iframe>');
          $('#communication-modal .modal-body').append(communication_frame);
          communication_frame.height(window.innerHeight * .7);
          $('#communication-modal').modal('handleUpdate');
          communication_frame.on('load', function() {
           spin_box.hide();
          });
          $('#communication-modal .modal-body').height(window.innerHeight * .7);
          $('#communication-modal').modal('handleUpdate');
        } else {
          $('#communication-modal iframe')[0].contentDocument.location.reload();
          spin_box.show();
        }
        if (addRefreshButton) {
          addRefreshButton = false;
          return $('#refresh-frame').click(function () {
            $('#communication-modal iframe')[0].contentDocument.location.reload();
            return spin_box.show();
          });
        }
      });
    }
  }

  shakeNotification() {
    $('#pending-communications').effect('shake', {
      times: 10,
      distance: 50
    }, 2500);
    this.notification_interval = setInterval((() => $('#pending-communications').effect('shake', {
      times: 10,
      distance: 50
    }, 2500)), 50000);
  }

  clearNotificationInterval() {
    if (this.notification_interval) {
      return window.clearInterval(this.notification_interval);
    }
  }
}

