import $ from 'jquery'

export class StoredPaymentMethod {
  static setup() {
    let $form = $("form#payment_method_form");

    $form.on("submit", function(e) {
      StoredPaymentMethod.submitPaymentMethod(this, e, true);
    });
  }
  static submitPaymentMethod(form, event, tokenize) {
    let $form = $(form);
    let guestPay = $('#guest-pay').length == 1;
    const paymentMethod = $form.find("input[name='payment_form[payment_method]']:checked").val();
    const $selectedDivision = $form.find('select[name*="division_id"] option:selected');
    let amount = 0;
    if (!$form.hasClass('new_payment_method_form') && $form.find('input[name="payment_form[amount]"]').length > 1) {
      amount = Number($form.find('input[name="payment_form[amount]"]:checked').val());
    } else {
      amount = Number($form.find('input[name="payment_form[amount]"]').val());
    }

    $form.find('.alert.alert-danger').remove();
    if ($selectedDivision.length > 0 && $selectedDivision.val() == '') {
      event.preventDefault();
      $form.prepend('<div class="alert alert-danger"><ul class="pl-2 pl-sm-3"><li>Division is required.</li></ul></div>');
      $form.find('input[type=submit]').removeAttr('disabled');
      $('html, body').animate({ scrollTop: $('.main-body .navbar').offset().top }, 800);
      return;
    } else if (!$form.hasClass('new_payment_method_form') && ((isNaN(amount) || amount == 0) || (paymentMethod != 'adjustment' && amount < 0))) {
      event.preventDefault();
      $form.prepend('<div class="alert alert-danger"><ul class="pl-2 pl-sm-3"><li>Please enter a valid amount.</li></ul></div>');
      $form.find('input[type=submit]').removeAttr('disabled');
      $('html, body').animate({ scrollTop: $('.main-body .navbar').offset().top }, 800);
      return;
    }

    $form.find('input[type=submit]').attr('disabled', true);
    if (!guestPay && !isNaN(amount) && amount > 500) {
      if (!window.confirm('Are you sure?')) {
        event.preventDefault();
        $form.find("input[type=submit]").removeAttr("disabled");
        return;
      }
    }

    if (tokenize) {
      event.preventDefault();

      let cardNumber = $form.find("*[name*='[payment_card_number]']").val();

      let secureData = {
        cardData: {
          cardNumber: cardNumber,
          month: $form.find("*[name*='[payment_card_expiration_month]']").val(),
          year: $form.find("*[name*='[payment_card_expiration_year]']").val(),
          cardCode: $form.find("*[name*='[payment_card_cvc]']").val(),
          zip: $form.find("*[name*='[payment_card_postal_code]']").val(),
          fullName: $form
            .find("*[name*='[payment_card_cardholder_name]']")
            .val()
        },
        authData: {
          clientKey: getClientKey(),
          apiLoginID: getLoginKey()
        }
      };

      Accept.dispatchData(secureData, responseHandler);

      function responseHandler(response) {
        if (response.messages.resultCode === "Error") {
          $form.find(".alert.alert-danger").remove();
          $form.find("input[type=submit]").removeAttr("disabled");
          let $error = $("<div>", {
            class: "alert alert-danger"
          });
          $form.find(".form-inputs").eq(0).prepend($error);
          $error.html('<ul class="pl-2 pl-sm-3"></ul>');
          for (let i = 0; i < response.messages.message.length; i++) {
            $error.find('ul').append(`<li>${response.messages.message[i].text}</li>`);
          }
          $('html, body').animate({ scrollTop: $('.main-body .navbar').offset().top }, 800);
        } else {
          $form.find("*[name*='[payment_card_number]']").attr("disabled", true);
          $form.find("*[name*='payment_card_cvc']").attr("disabled", true);
          let $token = $("<input>", {
            type: "hidden",
            name: $form.attr("id") + "[payment_method_token]",
            value: response.opaqueData.dataValue
          });
          $form.append($token);
          let $lastFour = $("<input>", {
            type: "hidden",
            name: $form.attr("id") + "[payment_card_last_four]",
            value: cardNumber.slice(-4)
          });
          $form.append($lastFour);
          form.submit();
        }
      }

      function getLoginKey() {
        if ($selectedDivision.val() === undefined) {
          return $form.find('input#payment_form_login_id').val();
        } else {
          return $selectedDivision.data("loginId");
        }
      }

      function getClientKey() {
        if ($selectedDivision.val() === undefined) {
          return $form.find('input#payment_form_client_key').val();
        } else {
          return $selectedDivision.data("clientKey");
        }
      }
    }
  }
}
