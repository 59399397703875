import $ from 'jquery'

export class ProductQuantities {
  static setup() {
    $('#product_quantity_copy_from_id').change(function() {
      if ($(this).val()) {
        return $.getJSON(`/product_quantities/${$(this).val()}/product_description`, function(data) {
          $('#product_quantity_quantity').val(data.quantity);
          $('#product_quantity_directions').val(data.directions);
          $('#product_quantity_directions_2').val(data.directions_2);
          return $('#product_quantity_quantity_description').val(data.quantity_description);
        });
      } else {
        $('#product_quantity_quantity').val('');
        $('#product_quantity_directions').val('');
        $('#product_quantity_directions_2').val('');
        return $('#product_quantity_quantity_description').val('');
      }
    })
  }
}
