import $ from 'jquery'

export class Refills {
    static setup() {
        $('.prescription-card.refillable').on('click', function(event) {
            let $checkbox;
            $checkbox = $(event.target).parents('.prescription-card').find('input.refill-check');
            return $checkbox.prop('checked', !$checkbox.prop('checked'));
        });
        $('.prescription-card.disabled').on('click', function(event) {
            return $(event.target).parents('.prescription-container').find('.prescription-alert').removeClass('d-none');
        });
        return $('.prescription-alert button.close').on('click', function(event) {
            event.preventDefault();
            return $(event.target).parent('.prescription-alert').addClass('d-none');
        });
    }
}
