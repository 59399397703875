export class PusherSetup {
    static setup() {
        if (window.Pusher) {
            const pusher_keys = $('#pusher-keys').data();
            if (pusher_keys === undefined) {
                return;
            }
            return Pusher.instance = new Pusher(pusher_keys.key, {
                cluster: pusher_keys.cluster,
                forceTLS: true
            });
        }
    }
}
