import { Patients } from '../src/patients';

export class PatientRegistrations {
  static setup() {
    if ($('#patient_registration_form').length > 0) {
      return this.setupPatientRegistrationForm();
    }
  }

  static setupPatientRegistrationForm() {
    // Hack to get chrome not to show the "you have unsaved changes, are you sure you want to leave"
    // popup when switching the language
    EventTarget.prototype.addEventListenerBase = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function(type, listener) {
      if (type == 'beforeunload') {
        return;
      }
      this.addEventListenerBase(type, listener);
    };

    // when a user hits the enter key, if it was on an input we'll direct them to the next input
    // This is useful on iOS, the "go" button on the keyboard sends a enter key, this basically remaps it to tab,
    // and if they hit enter on the submit button, it will submit the form as expected
    // credits to this underrated answer https://stackoverflow.com/a/46475171
    $(window).keydown(function(event) {
      if (event.keyCode == 13) {
        if ($(event.target).is('input') && !$(event.target).is('input[type="submit"]')) {
          event.preventDefault();
          const $form = $(event.target).closest('form');
          const index = $form.find('input:visible, select:visible, textarea:visible').index(event.target) + 1;
          const nextInput = $form.find('input:visible, select:visible, textarea:visible').get(index);
          if (nextInput) {
            nextInput.focus();
          }

          return false;
        }
      }
    });

    let $form = $('#patient_registration_form');
    $form.find('#patient_language').on('change', function() {
      if ($(this).val() != $(this).data('current-language')) {
        $('#patient_language_changed').val(true);
        $('#patient_bypass_address_validation').val(true);
        $form.find('input[type="submit"]').prop('disabled', true);
        $form.submit();
      }
    });

    const $matchPatientModal = $('#match-patient-modal');
    const $matchPatientForm = $matchPatientModal.find('#patient_match_form');
    $matchPatientForm.on('submit', function (event) {
      $matchPatientForm.find('.alert').toggle(false).remove();
      event.preventDefault();

      if (!Patients.patientMatchFormValidations($matchPatientForm)) {
        return false;
      }

      let birthDate = $matchPatientForm.find('#patient_birth_date').val();
      const email = $matchPatientForm.find('#patient_email').val() || '';
      const phoneNumber = $matchPatientForm.find('#patient_phone_number').val() || '';

      $.ajax({
        type: 'POST',
        url: $matchPatientForm.prop('action'),
        data: {
          patient: {
            encoded_id: $matchPatientForm.find('#patient_encoded_id').val(),
            birth_date: birthDate,
            email: email,
            phone_number: phoneNumber
          }
        },
        success(data) {
          if (data && data.id) {
            $form.find('#patient_encoded_id').val(data.id);
            $form.find('#patient_first_name').val(data.first_name);
            $form.find('#patient_middle_name').val(data.middle_name);
            $form.find('#patient_last_name').val(data.last_name);
            $form.find('#patient_birth_date').val(data.birth_date);
            $form.find('#patient_email').val(email.length > 0 ? email : data.email);
            $form.find('#patient_phone_number').val(phoneNumber.length > 0 ? phoneNumber : data.phone_number);
            $form.find('#patient_address_1').val(data.address_1);
            $form.find('#patient_address_2').val(data.address_2);
            $form.find('#patient_city').val(data.city);
            $form.find('#patient_state').val(data.state);
            $form.find('#patient_zipcode').val(data.zipcode);
            $form.find('#patient_bypass_address_validation').val(data.bypass_address_validation);
            $form.find('#patient_communication_method_id').val(data.communication_method_id);
            $matchPatientForm.find('input[type="submit"]').prop('disabled', false);
            $matchPatientModal.modal('hide');
          }
        },
        error(data) {
          $matchPatientModal.find('.modal-body').prepend(`
            <div class="alert in alert-danger" style="display: none">
              ${data && data.responseJSON && data.responseJSON.message ? data.responseJSON.message : 'Failed to find patient, please try again.'}
            </div>
          `);
          $matchPatientForm.find('.alert').toggle(400);
          $matchPatientForm.find('input[type="submit"]').prop('disabled', false);
        }
      });
    });

    $matchPatientModal.on('hide.bs.modal', function () {
      if ($form.find('#patient_birth_date').val() == '') {
        $form.find('#patient_birth_date').val($matchPatientForm.find('#patient_birth_date').val());
      }

      if ($form.find('#patient_email').val() == '') {
        $form.find('#patient_email').val($matchPatientForm.find('#patient_email').val());
      }

      if ($form.find('#patient_phone_number').val() == '') {
        $form.find('#patient_phone_number').val($matchPatientForm.find('#patient_phone_number').val());
      }
    });

    if ($form.find('input[name="patient[authorize_contact]"]:checked').val() == 'true') {
      $('fieldset#authorize-contacts-form').toggle(400);
    }

    $form.find('input[name="patient[authorize_contact]"]').on('change', function() {
      if ($form.find('input[name="patient[authorize_contact]"]:checked').val() == 'true') {
        $('fieldset#authorize-contacts-form').toggle(400);
      } else {
        $('fieldset#authorize-contacts-form').toggle(false);
      }
    });

    const $confirmationModal = $('#confirmation-modal');
    $form.on('submit', function(e) {
      if ($confirmationModal.data('confirmed')) {
        return null;
      }

      if ($form.find('input[name="patient[patient_registration_attributes][marketing_consent]"]').is(':checked')) {
        $confirmationModal.data('confirmed', true);
      } else {
        e.preventDefault();
        $form.find('input[type="submit"]').prop('disabled', true);
        $confirmationModal.modal('show');
      }
    });

    $confirmationModal.find('.confirm-button').on('click', function() {
      $form.find('input[name="patient[patient_registration_attributes][marketing_consent]"]').prop('checked', true);
      $confirmationModal.modal('hide');
      $confirmationModal.data('confirmed', true);
      $form.submit();
    });

    $confirmationModal.find('.cancel-button').on('click', function() {
      $confirmationModal.modal('hide');
      $confirmationModal.data('confirmed', true);
      $form.submit();
    });
  }
}
