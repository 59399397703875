export class ScanForm {
    constructor() {
        this.DWObject = null;
    }

    setup() {
        let currentObj = this;
        $(document).ready(function() {
            if($('#dwtcontrolContainer').length) {
                Dynamsoft.DWT.RegisterEvent('OnWebTwainReady',function() {
                    currentObj.Dynamsoft_OnReady();
                });

                const $form = $('#scan_form');
                $form.submit(function(e) {
                    if ($form.hasClass('edit_attachment_object') && $form.hasClass('dirty')) {
                        return true;
                    }

                    if(!('#{mode}' === 'edit' && currentObj.DWObject.CurrentImageIndexInBuffer === -1)){
                        e.preventDefault();
                        currentObj.uploadAttachment();
                        return false;
                    }
                });
            }
        });

        $('#btnScan').click(function() {
            currentObj.AcquireImage();
        });

        $('#btnUpload').click(function() {
            ScanForm.btnLoad_onclick();
        });
    }

    Dynamsoft_OnReady() {
        this.DWObject = Dynamsoft.DWT.GetWebTwain('dwtcontrolContainer');
        if (this.DWObject) {
            let count = DWObject.SourceCount;
            for (let i = 0; i < count; i++)
                document.getElementById("source").options.add(new Option(DWObject.GetSourceNameItems(i), i));
            // Get Data Source names from Data Source Manager and put them in a drop-down box
        }
    }

    AcquireImage() {
        let currentDwObject = this.DWObject;
        if (this.DWObject) {
            let OnAcquireImageSuccess, OnAcquireImageFailure;
            OnAcquireImageSuccess = OnAcquireImageFailure = function () {
                currentDwObject.CloseSource();
            };

            let scanner = $('#source').prop('selectedIndex');

            if(scanner === -1){
                ScanForm.bootstrap_alert('No scanner selected')
            } else {

                this.DWObject.SelectSourceByIndex(document.getElementById("source").selectedIndex); //Use method SelectSourceByIndex to avoid the 'Select Source' dialog
                this.DWObject.OpenSource();

                if (document.getElementById("feeder").checked) { //Use auto feeder or use the flatbed
                    this.DWObject.IfFeederEnabled = true; //Enbale Document Feeder
                }
                else {
                    this.DWObject.IfFeederEnabled = false
                }
                this.DWObject.IfDisableSourceAfterAcquire = true;    // Scanner source will be disabled/closed automatically after the scan.
                this.DWObject.IfShowUI = false;
                this.DWObject.AcquireImage(OnAcquireImageSuccess, OnAcquireImageFailure);
            }
        }
    }

    static OnSuccess() {
        console.log('successful');
    }
    static OnFailure(errorCode, errorString) {
        alert(errorString);
    }

    uploadAttachment() {
        let OnSuccess = function(httpResponse) {
            let url = $('#scan_form_details').data().attachmentUrl;
            window.location =  url + '?uploaded=true';
        };

        let OnFailure = function(errorCode, errorString, httpResponse) {
            ScanForm.bootstrap_alert(httpResponse);
        };
        if(this.DWObject.CurrentImageIndexInBuffer === -1){
            ScanForm.bootstrap_alert('No attachment added')
            return;
        }
        this.DWObject.HTTPPort = window.location.port;

        let useSSL = $('#scan_form_details').data().useSsl;
        this.DWObject.IfSSL = useSSL;

        let CurrentPathName = unescape(window.location.pathname); // get current PathName in plain ASCII
        let CurrentPath = CurrentPathName.substring(0, CurrentPathName.lastIndexOf("/") + 1);
        let strActionPage = CurrentPath;
        let mode = $('#scan_form_details').data().mode;
        if(mode === 'edit'){
            strActionPage += "update_scan";
        } else {
            strActionPage += 'create_scan';
        }
        let host = $('#scan_form_details').data().host;
        let strHostIP = host;

        let date = new Date();
        this.DWObject.SetHTTPFormField('authenticity_token', $('input[name=authenticity_token]').first().val());
        this.DWObject.SetHTTPFormField('title', $('#attachment_object_title').val());
        this.DWObject.SetHTTPFormField('prescription_number', $('#attachment_object_prescription_number').val() || '');
        this.DWObject.SetHTTPFormField('is_prescription', $('#attachment_object_is_prescription').prop('checked'));
        this.DWObject.SetHTTPFormField('attachable_id', $('#attachment_object_attachable_id').val());
        this.DWObject.SetHTTPFormField('attachable_type', $('#attachment_object_attachable_type').val());
        this.DWObject.SetHTTPFormField('attachment_type', $('#attachment_object_attachment_type').val());
        this.DWObject.HTTPUploadAllThroughPostAsPDF(
            strHostIP,
            strActionPage,
            new Date().getTime() + '.pdf',
            OnSuccess, OnFailure
        );
    }

    static btnLoad_onclick() {
        let OnSuccess = function() {};

        let OnFailure = function(errorCode, errorString) {};

        if (this.DWObject) {
            this.DWObject.IfShowFileDialog = true; // Open the system's file dialog to load image
            this.DWObject.LoadImageEx("", Dynamsoft.DWT.EnumDWT_ImageType.IT_ALL, OnSuccess, OnFailure); // Load images in all supported formats (.bmp, .jpg, .tif, .png, .pdf). OnSuccess or OnFailure will be called after the operation
        }
    }

    static bootstrap_alert(message) {
        $('#js_flash').html('<div class="alert alert-danger">'+message+'</div>')
    }
}


