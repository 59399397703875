export class AttachmentObjects {
    static setup() {
        $("#new_fax").on({
            "submit": function(e) {
                let fax_number;
                fax_number = $("#fax_fax_number");
                if (fax_number.val() === 'undefined' || fax_number.val() === "") {
                    alert("You must enter a Fax Number");
                    e.preventDefault();
                }
            }
        });
        AttachmentObjects.maybe_display_attachment_prescription_number($('#attachment_object_attachment_type'));
        AttachmentObjects.maybe_display_attachment_prescription_number($('#upload_attachment_object_attachment_type'));

        if ($("#fax_attachment_object_id").length){
            AttachmentObjects.populate_image_preview();
        }

        $("#attachment_object_attachment_type").change(function() {
            AttachmentObjects.maybe_display_attachment_prescription_number($(this));
        });

        $("#upload_attachment_object_attachment_type").change(function() {
            AttachmentObjects.maybe_display_attachment_prescription_number($(this));
        });

        return $("#fax_attachment_object_id").change(function() {
            AttachmentObjects.populate_image_preview();
        });
    }

    static maybe_display_attachment_prescription_number(element) {
        const $prescriptionNumber = $('.attachment_object_prescription_number');
        if ($prescriptionNumber.length == 0) {
            return false;
        }

        const prescriptionNumber = $('#attachment_object_prescription_number').val() || null;
        if (element.val() === 'prescription' || prescriptionNumber != null) {
            $prescriptionNumber.show();
        } else {
            $prescriptionNumber.hide();
        }
    }

    static populate_image_preview() {
        let data, preview_image;
        preview_image = $("#attachment_image_preview");
        data = $("#fax_attachment_object_id").val();
        return $.ajax({
            type: 'POST',
            url: '/attachment_objects/fill_preview',
            data: {
                attachment_object_id: data
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log("AJAX Error for preview");
            },
            success: function(data, textStatus, jqXHR) {
                if (data && data !== "") {
                    preview_image.attr("src", data);
                }
                console.log("AJAX working for preview!");
            }
        });
    }
}
