export class DynamicNotifications {
  static setup() {
    if (window.Pusher && Pusher.instance) {
      const user_id = $('body').data('current-user-id');
      const notifications_channel = Pusher.instance.subscribe('dynamic_notifications' + user_id);

      notifications_channel.bind('update', data => DynamicNotifications.buildNotification(data.message, data.type, data.link));
    }
  }

  static buildNotification (message, type, link) {
    const alert_box = $('<div class="alert fade in"><button type="button" class="close" data-dismiss="alert">×</button><span class="text"></span></div>');
    alert_box.addClass(type);
    alert_box.find('.text').html(message + " ");
    if(link) {
      const a = $('<a href="' + link.url + '">' + link.text + '</a>');
      alert_box.find('.text').append(a);
    }

    $('#flash-messages').append(alert_box);
  }
}
