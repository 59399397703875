import { DateTimePicker } from '../src/rad_common_js/dateTimePicker';
import { UserNotifications } from '../src/user_notifications';
import $ from 'jquery';
import moment from 'moment';

export class ConsentModal {
  static setup() {
    const $consentForm = $('.consent-form');
    const $modal = $('#consent-modal');

    if ($consentForm.length > 0) {
      const $consentType = $('#confirmation_consent_type');
      $('select#confirmation_reason option').each(function () {
        $(this).toggleClass('d-none', $(this).data('consent-type') != $consentType.val() && $(this).data('consent-type') != undefined);
      });

      $consentType.on('change', function () {
        $('select#confirmation_reason option').each(function () {
          $(this).toggleClass('d-none', $(this).data('consent-type') != $consentType.val() && $(this).data('consent-type') != undefined);
        });
      });
    }

    if (!$modal.length) {
      return;
    }


    $consentForm.find('button[type=submit]').click(e => {
      const $submitBtn = $(e.target);
      const $inputs = {
        consent_type: $consentForm.find('#confirmation_consent_type'),
        confirmed_by_customer_at: $consentForm.find('#confirmation_confirmed_by_customer_at'),
        order: $consentForm.find('#confirmation_order'),
        reason: $consentForm.find('#confirmation_reason'),
        note: $consentForm.find('#confirmation_note')
      };
      e.preventDefault();
      $submitBtn.attr('disabled', true);
      $.ajax({
        url: $consentForm.prop('action') + '.js',
        type: 'POST',
        dataType: 'script',
        data: $consentForm.serialize(),
        success: () => {
          $modal.modal('hide');
          const $consentProvided = $('form input.consent_provided');
          $consentProvided.val(true);

          if ($consentProvided.closest('form').data('remote') == true) {
            $consentProvided.closest('form').find('input[type=submit]').prop('disabled', false).click();
          } else {
            $consentProvided.closest('form').submit();
          }
        },
        error: response => {
          var errors = response.responseText && response.responseText.length > 0 ? JSON.parse(response.responseText).errors : false;
          $modal.find('.error-messages').empty();

          if (errors) {
            Object.keys($inputs).forEach(input => {
              $inputs[input].toggleClass('is-invalid', errors[input] !== undefined);
            });
            $modal.find('.error-messages').html(`
              <div class="alert in alert-danger">
                Unable to confirm:
                <ul>
                  ${Object.keys(errors).map(function(error) { return '<li>' + errors[error] + '</li>'; }).join('')}
                </ul>
              </div>
            `);
          } else {
            console.log(response);
          }
          $submitBtn.attr('disabled', false);
        }
      });
    });

    $modal.find('.selectpicker').selectpicker();

    $modal.find('button.no-consent').click(function(e) {
      UserNotifications.deleteUserNotification(e, function() {
        const $consentProvided = $('form input.consent_provided');
        if ($('#confirmation_consent_type').val() == 'payment_authorization') {
          $consentProvided.closest('form').find('input[type=submit]').attr('disabled', false);
          $('form#payment_form .alert-danger').remove();
          $('form#payment_form').prepend(`
            <div class="alert in alert-danger">
              Payment cannot be submitted without patient consent.
            </div>
          `);
        } else if ($consentProvided.length > 0 && $consentProvided.closest('form').find('input[type=submit]').prop('disabled')) {
          $consentProvided.val(false);
          $consentProvided.closest('form').submit();
        }
      });
    });

    DateTimePicker.addDateTimeEvent($modal.find('input.datetime_local'), Date.now());

    $modal.modal({ keyboard: false });
    $modal.on('hidden.bs.modal', function () { $modal.remove(); });
  }
}
